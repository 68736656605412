<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <div class="app-max-width">
        <ion-toolbar class="text-center">
        
          <ion-buttons slot="start">
            <ion-button @click.prevent="goBack()">
              <ion-icon :icon="chevronBack"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-title class="text-capitalize">Message</ion-title>
        
        </ion-toolbar>
      </div>
    </ion-header>
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div class="app-max-width">
        <div v-if="message">
          <ion-item v-if="message.sender" @click.prevent="updateRoute('/profile/' + message.sender.username)">
            <div tabindex="0"></div>
            <ion-avatar slot="start">
              <img v-if="message.sender.profile_photo_path == null" src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/thumbnails/5f922d6e06874.png">
              <img v-else :src="message.sender.profile_photo_path">
            </ion-avatar>            
            <ion-label>
              <h2>{{message.sender.name}}</h2>
            </ion-label>            
          </ion-item>
          <div class="padding-left-md padding-right-md" style="border-bottom:1px solid #eeeeee;">
            <h5 class="text-medium">{{message.created_at}}</h5>
            <h2>RE: {{message.subject}}</h2>
            <p>{{message.body}}</p>
          </div>
          <!-- Replies -->
          <div v-if="message.replies">
            <div v-for="reply in message.replies" :key="reply">
              <ion-item @click.prevent="updateRoute('/profile/' + reply.sender.username)">
                <div tabindex="0"></div>
                <ion-avatar slot="start">
                  <img v-if="reply.sender.profile_photo_path == null" src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/thumbnails/5f922d6e06874.png">
                  <img v-else :src="reply.sender.profile_photo_path">
                </ion-avatar>            
                <ion-label>
                  <h2>{{reply.sender.name}}</h2>
                </ion-label>            
              </ion-item>
              <div class="padding-md">
                <span class="text-medium">{{reply.created_at}}</span>
                <p>{{reply.body}}</p>
              </div>
            </div>
          </div>
          <!-- Reply form -->
          <div v-if="showReplyForm === false" class="text-center padding-md">
            <ion-button color="light" expand="full" @click.prevent="showReplyForm = !showReplyForm">Reply</ion-button>
          </div>
          <div v-if="showReplyForm === true" class="text-center padding-md">
            <ion-button color="light" expand="full" @click.prevent="showReplyForm = !showReplyForm">Cancel</ion-button>
          </div>
          <ion-item v-if="showReplyForm === true">
            <div tabindex="0"></div>
            <ion-textarea v-model="body" placeholder="Message" />
          </ion-item>
          <ion-item v-if="body != null && body != ''">
            <div tabindex="0"></div>
            <ion-button slot="end" @click.prevent="reply()">Send</ion-button>
          </ion-item>
        </div>
      </div>
    </ion-content>
    <!-- Footer -->
    <ion-footer>
      <Tabs page="message" />
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon, loadingController, toastController, IonItem, IonLabel, IonAvatar, IonTextarea } from '@ionic/vue';
import { chevronBack, apps, reorderThree, options } from 'ionicons/icons';
import { defineComponent, onMounted, ref, computed } from 'vue';
import doAuth from '../../services/auth';
import Tabs from '../../components/Tabs.vue';
//import SendMessageForm from '../../components/SendMessageForm.vue';
import { useRouter } from 'vue-router';
import apiClient from '../../services/api';
import { useStore } from "vuex";

export default defineComponent({
  name: 'MessageDetail',
  components: {
    IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon, Tabs, IonItem, IonLabel, IonAvatar, IonTextarea
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const router = useRouter();
    const messageId = ref(0);
    const message = ref([]);
    const timeout = { default: 6000 }
    const showReplyForm = ref(false);
    const body = ref(null);
    const toastMessage = ref(null);
    const receiverIds = ref([]);

    onMounted(() => {
      messageId.value = router.currentRoute._rawValue.params.id
      if(messageId.value > 0) {
        getMessage()
      }
    })

    function getMessage() {
      presentLoading()

      apiClient.get('/api/get/message/id/'+messageId.value,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          message.value = response.data
          if(message.value) {
            if(message.value.receivers.length > 0) {
              for(var i=0;i<message.value.receivers.length;i++) {
                receiverIds.value.push(message.value.receivers[i].user_id)
              }
            }
          }
        }).catch(error => {
          console.log(error)
        });         
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    function goBack() {
      router.go(-1)
    }

    function updateRoute(val) {
      router.push(val)
    }

    function reply() {
      if(body.value != null && body.value != '') {
        presentLoading()
        apiClient.post('/api/post/reply', 
          { 
            sender_id: authUser.value.id,
            receiver_ids: receiverIds.value,
            parent_id: messageId.value,
            subject: null,
            body: body.value
          },
          {
            headers: {
              'Authorization':  `Bearer ${store.state.authToken}`
            }
          }).then(response => {
            if(response.data.message == 'success') {
              toastMessage.value = 'Your message has been sent'
              body.value = null
            }
            if(response.data.message == 'fail') {
              toastMessage.value = 'Something went wrong.'
            }
            if(response.data.message == 'fail-validation') {
              toastMessage.value = 'You are not authorized to do that.'
            }
            if(response.data.message == 'fail-auth') {
              toastMessage.value = 'You must be signed in.'
            }
            openToast()
            getMessage()
          }).catch(error => {
            console.log(error)
          });
      }      
    }

    return {
      chevronBack, router, goBack, messageId, apps, reorderThree, options, updateRoute, message, showReplyForm, body, reply, receiverIds
    }
  },
});
</script>

<style scoped>

</style>